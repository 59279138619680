import Image002 from "../assets/ipc_image002.png"

export function IPC_Features() {
	return (
		<>
			<div className="normal-text">

				<h1>
					<img width="62" height="62" src={Image002} />
					<span style={{ "color": "#005F00", "paddingLeft": "20px" }} >Interactive Path Chart Features &amp; Revision History</span>
				</h1>

				<p>&nbsp;</p>

				<h2>July 3, 2024 (release 1.0.0)</h2>

				<ul>
					<li>App Settings: Timeline Column, Dependencies Column, Scaling, Group, Display All Groups, Save Button</li>
					<li>Support settings changes</li>
					<li>Read items from associated monday.com board</li>
					<li>Display items in compact scaling grid</li>
					<li>Display items in real timeline scaling grid</li>
					<li>Display dependency relationships</li>
					<li>Display legends</li>
					<li>Support vertical scrolling with scrollbar or mouse wheel</li>
					<li>Refresh layout option</li>
					<li>Help/Instructions option</li>
					<li>Display date picker modal on item click</li>
					<li>Support drag-and-drop of items, managing dependencies</li>
					<li>Save changes back to associated board upon request</li>
				</ul>

				<p>&nbsp;</p>

				<h2>October 31, 2024 (release 1.0.1)</h2>

				<ul>
					<li>Support monday.com app view filters and item additions</li>
					<li>Fix minor visualization bugs</li>
				</ul>

				<p>&nbsp;</p>

				<h2>January 16, 2025 (release 1.1.0)</h2>

				<ul>
					<li>2X, 4X, and 8X zoom levels with horizontal scrolling</li>
					<li>Daily and Weekly dates resolution setting options</li>
					<li>Critical path highlighting with corresponding legend entry</li>
					<li>Grid expansion for drag and drop actions</li>
					<li>Minor bug fixes and feature enhancements</li>
				</ul>

				<p>&nbsp;</p>

			</div>
		</>
	);
}
